import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({providedIn:'root'})
export class ApiInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem('acc_tkn');
    if(!idToken) { // <--- not logged-in skip adding the header
      return next.handle(request);
    }

    if (request && (request.url.includes('execute-api')   ||request.url.includes('.previospichincha.com'))) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${idToken}`,
        },
      });
    }
    return next.handle(request);
  }
}
