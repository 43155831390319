<div class="welcome__header">
  <div class="welcome__cont">
    <div class="welcome__cont_title">
      <h2 class="welcome__cont_title__title">Bienvenido, {{userDisplayName}}</h2>
      <h3 class="welcome__cont_title__subtitle">a la plataforma de gestión comercial</h3>
    </div>
  </div>
  <p class="welcome__select_product"> Selecciona el producto: </p>

  <form [formGroup]="reactiveForm" (ngSubmit)="goToHome()" class="centered-form">
    <div class="product-options">

      <!-- Libranza -->
      <div class="product-item">
        <div class="product-option"
             [class.selected]="selectedProduct === 'libranza'"
             (click)="selectProduct('libranza')">
          <div class="product-icon">
            <img src="assets/img/libranza-icon.svg" alt="Libranza">
          </div>
        </div>
        <span class="product-name">Libranza</span>
      </div>

   </div>

    <div class="section__btns col-md-12 d-flex justify-content-center mt-3">
      <button type="submit" class="pi-btn pi-btn-yellow" [disabled]="!selectedProduct">
        Continuar
      </button>
    </div>
  </form>
</div>
