import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AccountInfo } from '@azure/msal-browser';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule]
})
export class ProductSelectionComponent implements OnInit {
  reactiveForm: FormGroup;
  currentUser: AccountInfo = {} as any;
  isMock!: boolean;
  userDisplayName: string | null = null;
  selectedProduct: string | null = null;

  private _router = inject(Router);
  private _authS = inject(AuthService);
  private _formBuilder = inject(FormBuilder);

  constructor() {
    this.reactiveForm = this._formBuilder.group({
      product: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.isMock = window?.localStorage?.getItem('is_mock') ? true : false;

    this._authS.checkAndSetActiveAccount();
    this._authS.currentUser.subscribe(user => {
      this.userDisplayName = !this.isMock ? user?.name ?? '' : 'Usuario (Externo)';
    });
  }

  selectProduct(product: string) {
    if (this.selectedProduct === product) {
      // Si el producto ya está seleccionado, lo deseleccionamos
      this.selectedProduct = null;
      this.reactiveForm.patchValue({ product: null });
    } else {
      // Si es un producto diferente o no hay selección, lo seleccionamos
      this.selectedProduct = product;
      this.reactiveForm.patchValue({ product: product });
    }
  }

  goToHome() {
    if (this.reactiveForm.valid) {
      // Redirect to welcome
      this._router.navigate(['/welcome']);
    }
  }
}
