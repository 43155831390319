import { Component } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-CO';
registerLocaleData(localeEsAr);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'bp-librz-mto-front';
  constructor() {}
  
}
