import { Component, OnInit } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionListenerService } from 'app/shared/services/session-listener/session-listener.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent implements OnInit {
  userDisplayName: string | null = null;
  currentUser: AccountInfo = {} as any;
  isMock: boolean;

  constructor(private _authS: AuthService, private _ssListenerS: SessionListenerService) {
    this.isMock = window?.localStorage?.getItem('is_mock') ? true : false;
  }

  ngOnInit() {
    this._authS.checkAndSetActiveAccount();
     this._authS.currentUser.subscribe( user => {
      this.userDisplayName = !this.isMock ? user?.name ?? '' : 'Mock User';
    })
  }

  closeModal(){
    this._ssListenerS.reviveListener();
  }

}
