import { MSAL_INSTANCE, MsalGuard, MsalService } from '@azure/msal-angular';
import { AuthService } from '../services/auth.service';
import { SessionListenerService } from '../services/session-listener/session-listener.service';
import { SpinnerService } from '../services/shared-service/spinner.service';
import { MsalMockGuard } from '../mocks/msal-service-mock.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from '../interceptors/loader-interceptor/interceptor-loader.service';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { MY_FORMATS } from 'app/pichincha-libranza-operativa/modules/sales-reassignment/components/modal-reports/modal-reports.component';
import { MSALInstanceFactory } from '../constants/msal.conf';
import { ApiInterceptor } from '../interceptors/api.interceptor';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';

export const UBuildProviders = (isMock: any) =>
  isMock
    ? [
      AuthService,
      SessionListenerService,
      SpinnerService,
      MsalService,
      { provide: LOCALE_ID, useValue: 'es-CO' },
      {provide: DEFAULT_CURRENCY_CODE, useValue: 'COP'},
      { provide: MsalGuard, useClass: MsalMockGuard },
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true,
      },
      {
        provide: DateAdapter,
        useClass: NativeDateAdapter,
      },
      { provide: MAT_DATE_LOCALE, useValue: 'es-FR' },
      { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiInterceptor,
        multi: true
      }
    ]
    : [
      AuthService,
      SessionListenerService,
      SpinnerService,
      MsalService,
      MsalGuard,
      { provide: LOCALE_ID, useValue: 'es-CO' },
      {provide: DEFAULT_CURRENCY_CODE, useValue: 'COP'},
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true,
      },
      {
        provide: DateAdapter,
        useClass: NativeDateAdapter,
      },
      { provide: MAT_DATE_LOCALE, useValue: 'es-FR' },
      { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, 
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiInterceptor,
        multi: true
      }
    ];
