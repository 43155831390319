<!-- modal.component.ts -->
<div class="modal fade" [id]="idModal" tabindex="-1" aria-labelledby="messageModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container ">
                    <h5 class="modal-title font-weight-bold text-center mt-4">
                        {{ message }}
                    </h5>
                    <button type="button" class="pi-btn pi-btn-yellow" data-bs-dismiss="modal" aria-label="Close"
                        (click)="closeModal(true)">Aceptar</button>
                </div>
            </div>
        </div>
    </div>
</div>