import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './shared/layout/main-layout/main-layout.component';
import { MsalRedirectComponent, MsalGuard } from '@azure/msal-angular';
import { MsalWildcardComponent } from '@global-shared/components/msal-wildcard/msal-wildcard.component';
import { ProductSelectionComponent } from './pichincha-libranza-operativa/modules/welcome/pages/product-selection/product-selection.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children:[
      {
        path: '',
        component: MsalWildcardComponent,
      },
      {
        path: 'auth', 
        component: MsalRedirectComponent
      },
      {
        path: 'product',
        canActivate: [MsalGuard],
        component: ProductSelectionComponent,
      },
      {
        path: 'code',
        component: MsalWildcardComponent,
      },
      {
        path: 'welcome',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pichincha-libranza-operativa/modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'cases',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pichincha-libranza-operativa/modules/consult-cases/consult-cases.module').then(
            (m) => m.ConsultCasesModule
          ),
      },
      {
        path: 'reports',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pichincha-libranza-operativa/modules/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'simulacion',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pichincha-libranza-operativa/modules/simulation/simulation.module').then(
            (m) => m.SimulationModule
          ),
      },
      {
        path: 'perfilamiento',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pichincha-libranza-operativa/modules/profiling/profiling.routes').then(
            (m) => m.ProfilingRoutes
          ),
      },
      {
        path: 'reassignment',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pichincha-libranza-operativa/modules/sales-reassignment/sales-reassignment.routes').then(
            (m) => m.SalesReassignmentRoutes
          ),
      },
      {
        path: 'crear-venta-prospecto',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pichincha-libranza-operativa/modules/prospect-sale/prospect-sale.routes').then(
            (r) => r.routes
          ),
      },
      {
        path: 'gestion-de-casos',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pichincha-libranza-operativa/modules/cases-management/cases-management.routes').then(
            (r) => r.CasesRoutes
          ),
      },
      {
        path: '**',
        redirectTo: 'product',
        pathMatch: 'full',
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "top",
      initialNavigation: 'enabledBlocking'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
