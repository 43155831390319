import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'environments/environment';
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const MSAL_GENERAL_CONF: Configuration = {
  auth: {
    clientId: environment.clientId,
    authority: environment.tenentId,
    redirectUri: environment.frontUrl,
    postLogoutRedirectUri: environment.frontUrl,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
};

export const MSAL_LOGIN_CONF: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: { scopes: ['user.read', 'openid'] },
  loginFailedRoute: '/',
};

export const MSAL_INTERCEPTOR_CONF: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read','openid']],
  ]),
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.tenentId,
      redirectUri: environment.frontUrl,
      postLogoutRedirectUri: environment.frontUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false,
    },
  });
}
