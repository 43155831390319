import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-session-modal',
  templateUrl: './confirm-session-modal.component.html',
  styleUrl: './confirm-session-modal.component.scss',
  standalone: true
})
export class ConfirmSessionModalComponent {
  @Output() onConfirm: EventEmitter<boolean> = new EventEmitter();
  @Input() message: string = '¿Supero el tiempo máximo de inactividad, desea renovar la sesión?';
  @Input({ required: true }) idModal: string = 'messageModal';
  

  closeModal(confirm: boolean){
    this.onConfirm.emit(confirm);
  }

}
