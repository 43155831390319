import * as Forge from 'node-forge'
import { environment } from '../../../../environments/environment';


export function inicializarLlaveSerializacion() {
        let sessionCode = sessionStorage.getItem('code');
        if(sessionCode == undefined){
            let salt = Forge.random.getBytesSync(128);
            let key = Forge.pkcs5.pbkdf2(environment.sourceStorageKey, salt, 4, 16);
            let iv = Forge.random.getBytesSync(16);
            let code = `${Forge.util.encode64(iv)}:${Forge.util.encode64(key)}`
            let secOnSession = sessionStorage.getItem('code');
            if (!secOnSession) sessionStorage.setItem('code', code);
        }


}

export function serializador(data: any) {
    let sessionCode = sessionStorage.getItem('code');
    if(sessionCode == undefined){
        inicializarLlaveSerializacion()
    }
    let code = sessionStorage.getItem('code');
    let result: any;
    if (environment.enableSourceStorage && code) {
        let iv = code.split(':')[0];
        let key = code.split(':')[1];
        let cifrado = Forge.cipher.createCipher('AES-CBC', Forge.util.decode64(key));
        cifrado.start({ iv: Forge.util.decode64(iv) });
        cifrado.update(Forge.util.createBuffer(JSON.stringify(data)));
        cifrado.finish()
        result = cifrado.output.toHex();
    } else {
        result = JSON.stringify(data);
    }
    return result;

}
export function desSerializador(data: any) {

    let code = sessionStorage.getItem('code');
    let result: any;
    if (environment.enableSourceStorage && code) {
        let iv = code.split(':')[0];
        let key = code.split(':')[1];
        let descifrado = Forge.cipher.createDecipher('AES-CBC', Forge.util.decode64(key));
        descifrado.start({ iv: Forge.util.decode64(iv) });
        descifrado.update(Forge.util.createBuffer(Forge.util.hexToBytes(data)));
        descifrado.finish()
        result = JSON.parse(descifrado.output.data)
    } else {
        result = JSON.parse(data);
    }
    return result;

}
