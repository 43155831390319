import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-msal-wildcard',
  template: '<span></span>',
  standalone: true
})
export class MsalWildcardComponent implements OnInit {

  constructor(private _router: Router, private _authS: AuthService){}

  ngOnInit(): void {
    console.log("this._authS.exitAccount", this._authS.exitAccount)
    if(!this._authS.exitAccount) {
      this._router.navigate(['product']);
    }
  }
}
