import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { NgxsModule } from '@ngxs/store';
import { BackofficeState } from './shared/model/state/backoffice.state';
import { desSerializador, serializador } from './shared/model/state/SecurityState';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MainLayoutComponent } from './shared/layout/main-layout/main-layout.component';
import { MSAL_GENERAL_CONF, MSAL_LOGIN_CONF, MSAL_INTERCEPTOR_CONF } from './shared/constants/msal.conf';
import { GraphQLModule } from './graphql.module';
import { ConfirmSessionModalComponent } from './shared/components/confirm-session-modal/confirm-session-modal.component';
import { MatNativeDateModule } from '@angular/material/core';
import { UBuildProviders } from './shared/utils/provides.util';
import { ActionValidateDirective } from '@global-shared/directives/action-validate.directive';

const isMock = window?.localStorage?.getItem('is_mock') ?? null;
const providers = UBuildProviders(isMock);

@NgModule({
  declarations: [AppComponent, MainLayoutComponent],
  imports: [
    MsalModule.forRoot(
      new PublicClientApplication({ ...MSAL_GENERAL_CONF }),
      { ...(MSAL_LOGIN_CONF as any) },
      { ...(MSAL_INTERCEPTOR_CONF as any) }
    ),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    ActionValidateDirective,
    NgxsModule.forRoot([BackofficeState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage,
      deserialize: desSerializador,
      serialize: serializador,
    }),
    HttpClientXsrfModule.withOptions({ cookieName: 'XSRF-TOKEN' }),
    NgbModule, // Opcional: configuración de XSRF,
    ConfirmSessionModalComponent,
    GraphQLModule,
    MatNativeDateModule
  ],
  providers,
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(private localeService: BsLocaleService) {
    defineLocale('es', esLocale); // Define el idioma español
    this.localeService.use('es'); // Usa el idioma español
  }
}
